import ContactFormPanel from "./ContactFormPanel";
import { ToastContainer, toast } from "react-toastify";

export default function Modal(props: any) {
  const { showModal, setShowModal, closeToast } = props;

  return (
    <>
      {showModal &&
        <>
          <span className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <span className="relative w-auto my-6 mx-auto max-w-6xl">
              {/*content*/}
              <span className="border-0 rounded-lg shadow-sm shadow-white relative flex flex-col w-full bg-black outline-none focus:outline-none animate-fadeInUp05">
                {/*header*/}
                <span className="flex items-start justify-between p-5 border-b border-solid rounded-t">
                  <h3 className="text-3xl font-semibold text-white">
                    Contact Us
                  </h3>
                  <button className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)} >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </span>
                {/*body*/}
                <span className="relative p-6 flex-auto">
                <ContactFormPanel setShowModal={setShowModal} closeToast={closeToast} />
                </span>
              </span>
            </span>
          </span>
          <span className="opacity-25 fixed inset-0 z-40 bg-black"></span>
        </>
      }
    </>
  )
}