import ContentQueue from "../Content/ContentQueue";
import FocusedContent from "../Content/FocusedContent";
import { useEffect, useState } from 'react'

export default function Commercials(props: any) {
  const { setShowNav } = props;
  
  useEffect(() => {
    setShowNav(true)
  }, [setShowNav])
  
  const [focusedContentId, setFocusedContentId] = useState('')
  const updateFocusedContentId = (focusedContentId: string):void => {
     setFocusedContentId(focusedContentId)
  }
  return (
    <span className='flex flex-col md:flex-row w-full h-full'>
      <FocusedContent focusedContentId={focusedContentId} />
      <ContentQueue category={'Commercials'} updateFocusedContentId={updateFocusedContentId} />
    </span>
  )
}
