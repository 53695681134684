import {Typography} from "@material-tailwind/react";
import { NavLink } from 'react-router-dom';
import SocialMediaPanel from "../SocialMedia/SocialMediaPanel";
import { RouteDetail } from "../../types/RouteDetail";
import RevealDown from "../Animation/RevealDown";

export default function NavigationList() {
  const routes: RouteDetail[] = [
    { text: 'Commercials', route: '/Commercials' },
    {text: 'Short Films', route: '/ShortFilms'},
    {text: 'Music Videos', route: '/MusicVideos'},
    {text: 'Projects & Collabs', route: '/ProjectsAndCollabs'},
    {text: 'About Chuck', route: '/AboutChuck'},
  ]
  return (
    <ul className="flex flex-col items-center justify-end text-right gap-2 lg:flex-row lg:gap-6">
      
      {routes.map((route: RouteDetail, i: number) => {
        return <Typography key={i} as="li" variant="small" className="p-1 font-normal≈">
                <RevealDown  duration={(i*0.1)}>
                  <NavLink className="flex items-center text-right text-sm md:text-md text-white" to={route.route}>{ route.text }</NavLink>
                </RevealDown>
          </Typography>
      })}

      <Typography as="li" variant="small" className="p-1 font-normal">
        <SocialMediaPanel />
      </Typography>

    </ul>
  )
}
