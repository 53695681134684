import { NavLink } from 'react-router-dom';
import PhotoAlbumContainer from '../PhotoAlbumContainer';
import '../../index.css'
import { useEffect } from 'react';
import Reveal from '../Animation/Reveal';

export default function Landing(props: any) {
  const { setShowNav } = props;

  useEffect(() => {
    setShowNav(false)
  }, [setShowNav])

  useEffect(() => {

  }, [])
  
  return (
    <span className='flex items-center justify-center absolute h-screen w-screen bg-black'>
      <span  className='flex absolute h-full w-full'>
        <PhotoAlbumContainer/>
      </span>

      <span className='flex flex-col items-center justify-center z-10 space-y-6 brightness-105 overflow-hidden relative'>
        <Reveal>
          <p className='text-white font-bold text-6xl lg:text-8xl flex-wrap font-Tourney w-8/12 text-center'>CHUCK STYLES CONCEPTS</p>
        </Reveal>

        <Reveal>
          <NavLink to="/Commercials"  className='flex justify-center items-center w-full h-full z-50'>
            <button className="text-white font-extrabold uppercase text-2xl px-8 py-4 mr-1 mb-1 rounded-md hover:scale-95 hover:underline ease-linear transition-all duration-150 border-white border-4">
              EXPLORE MORE
            </button>
          </NavLink>
        </Reveal>

      </span>
    </span>
  )
}
