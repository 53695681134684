import { useState,useEffect } from 'react'
import { sanityClient } from '../../client';
import { getVideoCredits } from '../../lib/contentQueries/media';
import Popover, { PopoverContent, PopoverHandler } from '@material-tailwind/react/components/Popover';


export default function VideoCreditsPopOver(props:any) {
  const { focusedContentId } = props;
  const [name, setName] = useState<string>('' as any)
  const [stylist, setStylist] = useState<string>('' as any)
  const [production, setProduction] = useState<string>('' as any)
  const [director, setDirector] = useState<string>('' as any)

    useEffect(() => {
        sanityClient
    .fetch(getVideoCredits(focusedContentId))
          .then((data: any) => {
            setName(data.name)
            setStylist(data.stylist)
            setProduction(data.production)
            setDirector(data.director)
    })
	.catch(console.error);
    },[focusedContentId, name])

    
  return (
      <span className='flex top-4 right-4'>
        <Popover placement="top-end">
                    <PopoverHandler>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 hover:scale-150">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                      </svg>
                    </PopoverHandler>
                    <PopoverContent className='flex flex-col bg-black w-auto'>
                    <span className='flex text-lg border-b-2 p-2 text-white font-semibold'>{name}</span>
                    <span className='flex flex-col p-2 text-white w-full space-y-1'>
                        <span className='flex flex-row whitespace-nowrap'>
                          {stylist ?
                          <span className='flex whitespace-nowrap'>
                            <span className='flex text-sm font-semibold'>Stylist : </span>
                            <span className='flex text-sm'>&nbsp;{stylist}</span>
                          </span>  :
                          <></>
                          }
                        </span>
                        <span className='flex flex-row whitespace-nowrap'>
                          {production ?
                          <span className='flex whitespace-nowrap'>
                            <span className='flex text-sm font-semibold'>Production : </span>
                            <span className='flex text-sm'>&nbsp;{production}</span>
                          </span>  :
                          <></>
                          }
                        </span>
                        <span className='flex flex-row whitespace-nowrap'>
                          {director ?
                          <span className='flex whitespace-nowrap'>
                            <span className='flex text-sm font-semibold'>Director : </span>
                            <span className='flex text-sm'>&nbsp;{director}</span>
                          </span>  :
                          <></>
                          }
                        </span>
                    </span>
                    </PopoverContent>
                  </Popover>
      </span>
  )
}
