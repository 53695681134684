import { useState, useEffect } from "react";
import {Navbar,MobileNav, Collapse} from "@material-tailwind/react";
import NavigationList from "./NavigationList";
import IconButtonPanel from "./IconButtonPanel";
export default function NavigationBar() {
  const [openNav, setOpenNav] = useState(false);
 
  useEffect(() => {
    window.addEventListener("resize", () => window.innerWidth >= 960 && setOpenNav(false));
  }, []);
 
  return (
    <Navbar className="p-4 lg:px-4 lg:py-4 bg-black text-white">
      <span className="container flex items-center justify-end">
          <span className="hidden lg:block">
              <NavigationList/>    
          </span>
          <span className="flex pr-6">
            <IconButtonPanel openNav={openNav} setOpenNav={setOpenNav} />
            </span>
      </span>
  
      <Collapse open={openNav}>
          <span className="container flex justify-end p-4">
            <span>
              <NavigationList />
            </span>
          </span>
      </Collapse>
    </Navbar>
  );
}
