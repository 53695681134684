import React from 'react'
import Modal from './Modal';
import { toast, ToastContainer } from 'react-toastify';

export default function ContactButton() {
    const envelope = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
</svg>

  
  const [showModal, setShowModal] = React.useState(false);
  const closeToast = () => {
    toast.success("Thank you for reaching out. We will be in touch soon🙏🏾")
    setShowModal(false);
  }
  
  return (
    <>
      <span onClick={()=> setShowModal(!showModal)}  className='flex hover:cursor-pointer hover:scale-110 bg-black text-white p-3 rounded-full border-2 border-white'>
          {envelope}
      </span>
      <Modal showModal={showModal} setShowModal={setShowModal} closeToast={closeToast} />
        <ToastContainer />
    </>
  )
}
