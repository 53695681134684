import {useEffect} from 'react'

export default function ImageContainer(props: any) {
    const { imageUrl } = props
    useEffect(() => {
    }, [imageUrl])
  return (
    <span className='flex flex-row items-center justify-start p-0 md:p-4'>
        <span className='flex justify-center items-center w-full h-[40vh] md:h-[80vh] hover:cursor-pointer hover:scale-105 bg-black'>
                <img className="h-full rounded-lg" src={imageUrl.image} height={10} alt='Styling_Style' />
        </span>
      <span className='text-white'>
      </span>
      </span>
  )
}
