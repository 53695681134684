
import { Routes, Route } from 'react-router-dom';
import Commercials from '../Pages/Commercials';
import About from '../Pages/About';
import MusicVideos from '../Pages/MusicVideos';
import ProjectsAndCollabs from '../Pages/ProjectsAndCollabs';
import ShortFilms from '../Pages/ShortFilms';
import Landing from '../Pages/Landing';

export default function NavigationRoutes(props: any) {
    const { setShowNav } = props;
    const routes = [
        { path: "/", element: <Landing setShowNav={setShowNav} /> },
        { path: "/Commercials", element: <Commercials setShowNav={setShowNav} /> },
        { path: "/ShortFilms", element: <ShortFilms setShowNav={setShowNav} /> },
        { path: "/MusicVideos", element: <MusicVideos setShowNav={setShowNav} /> },
        { path: "/ProjectsAndCollabs", element: <ProjectsAndCollabs setShowNav={setShowNav} /> },
        { path: "/AboutChuck", element: <About setShowNav={setShowNav} /> },
    ]
    return (
        <Routes>
            {routes.map((route: any) => {
                return  <Route key={route.path} path={route.path} element={route.element} />
            })}
        </Routes>
  )
}
