import { useEffect } from 'react';
import ReactPlayer from 'react-player/lazy'
export default function VideoPlayer(props: any) {
  const { videoUrl } = props;

  useEffect(() => {
    console.log(videoUrl);
  },[])

  return (
      <span className='flex items-center justify-center w-full h-full relative'>
      {/* <ReactPlayer
        controls
        url={videoUrl}
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload'
            }
          }
        }}
        height='100%'
        width='100%'
        /> */}
      <div className='' style={{ width: '200px', height: '200px', position: 'absolute', opacity: 0, right: '0px', top: '0px', zIndex: 999 }}> </div>
      
      <iframe title={videoUrl} seamless={false} src={videoUrl} allow="autoplay" height={'100%'} width={'100%'} allowFullScreen={true} loading='eager'></iframe>
      </span>
  )
}
