import LogoPanel from "../LogoPanel";
import NavigationBar from "./NavigationBar";

export default function NavigationPanel() {
  return (
    <span className="flex justify-center items-center h-auto bg-black pt-8 pb-8">
      <span className="h-full flex bg-black ml-4 lg:ml-24">
        <LogoPanel/>
      </span>
      <NavigationBar />
    </span>
  )
}
