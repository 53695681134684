import { useEffect, useRef, useState } from "react";
import emailjs from '@emailjs/browser';
import 'react-toastify/dist/ReactToastify.css';
import ContactFormFooter from "./ContactFormFooter";

export default function  ContactFormPanel(props:any) {
  const { setShowModal, closeToast } = props;
  const form = useRef({} as any)
  const [loading, setLoading] = useState(false);
  const dropDownOptions = [
    {value: "stylistBooking", text: "Stylist Booking"},
    { value: "wardrobeCall/FittingBooking", text: "Wardrobe Call/Fitting" },
    { value: "wardrobe/ClothingHire" , text: "Wardrobe/Clothing Hire" },
    { value: "queries", text: "Queries" }
  ]

  useEffect(() => {
    let toEmailInputElement = document.getElementById("toEmail") as HTMLInputElement;
    toEmailInputElement.value = "lindiwe@chuckstylesconcepts.com";
  },[])

  const sendEmail = (e: any) => {
    console.log('Email service details ', process.env.NEXT_PUBLIC_EMAIL_SERVICE_ID, process.env.NEXT_PUBLIC_EMAIL_TEMPLATE_ID, process.env.NEXT_PUBLIC_EMAIL_PUBLIC_KEY)
    e.preventDefault()
    setLoading(true)
    emailjs.sendForm('nolo_service_80u9dvd', 'template_29bvcic', form.current,  'ex0GVlQkcsP4A_KRW')
      .then((result:any) => {
        console.log(result.text);
        e.target?.reset();  
        closeToast()      
      }).catch((error:any) => {
        console.log(error.text);
      }).finally(() => {
        setLoading(false);
      });
  };

  const subjectSelectChange = (selectedValue: string) => {
  const toEmailInputElement = document.getElementById("toEmail") as HTMLInputElement;
    if (toEmailInputElement)
      if (selectedValue === "stylistBooking")
        toEmailInputElement.value = "lindiwe@chuckstylesconcepts.com";
      else
      {
        toEmailInputElement.value = "info@chuckstylesconcepts.com";
        const ccEmailInputElement = document.getElementById("ccEmail") as HTMLInputElement;
        ccEmailInputElement.value = "lindiwe@chuckstylesconcepts.com";
        }
  }

  return (
    <>
            <form id="form" name="form"  ref={form} onSubmit={sendEmail} className="flex flex-col w-full h-auto items-start justify-start p-2 bg-black rounded-md space-y-2 text-white" >

              <span className="flex flex-row w-full space-x-4">
                  <label className="flex flex-col w-full">
                        <span className="flex flex-row text-white font-semibold">First Name</span>
                        <input id="firstName" name="firstName"  type="text" placeholder="" required className="flex items-start w-full px-2 py-2 mt-2 rounded-md shadow-sm border-indigo-100 text-black"/>
                  </label>
          
                    <label className="flex flex-col w-full">
                        <span className="flex flex-row font-semibold">Last Name</span>
                        <input id="lastName" name="lastName"  type="text" required className="flex items-start w-full px-2 py-2 mt-2 rounded-md shadow-sm border-indigo-100 text-black" placeholder=""/>
                    </label>
              </span>
        
              <span className="flex-row w-full items-start justify-start hidden">
                    <label className="flex flex-col w-full">
                        <span className="flex flex-row font-semibold">E-mail</span>
                        <input id="toEmail" name="toEmail"placeholder=""/>
                    </label>
              </span>
        
        
              <span className="flex-row w-full items-start justify-start hidden">
                    <label className="flex flex-col w-full">
                        <span className="flex flex-row font-semibold">E-mail</span>
                        <input id="ccEmail" name="ccEmail" placeholder=""/>
                    </label>
              </span>
        
              <span className="flex flex-row w-full items-start justify-start">
                    <label className="flex flex-col w-full">
                        <span className="flex flex-row font-semibold">E-mail</span>
                        <input required id="fromEmail" name="fromEmail" className="flex items-start w-full px-2 py-2 mt-2 rounded-md shadow-sm border-indigo-100 text-black" placeholder=""/>
                    </label>
        </span>
        

        
              <span className="flex flex-row w-full items-start justify-start">
                    <label className="flex flex-col w-full">
                        <span className="flex flex-row font-semibold">Phone Number</span>
                        <input required id="phoneNumber" name="phoneNumber" className="flex items-start w-full px-2 py-2 mt-2 rounded-md shadow-sm border-indigo-100 text-black" placeholder=""/>
                    </label>
              </span>
        
              <span className="flex flex-row w-full items-start justify-start">
                    <label className="flex flex-col w-full">
                        <span className="flex flex-row font-semibold">Subject</span>
                          <select onChange={(e: any) => subjectSelectChange(e.target.value)} id="service" name="service" className="flex items-start w-full px-2 py-2 mt-2 rounded-md shadow-sm border-indigo-100 text-black" required>
                            {dropDownOptions.map((option: any) => {
                              return <option key={option.text} value={option.value}>{option.text}</option>
                            })}
                          </select>
                  </label>
              </span>
        
              <span className="flex flex-row w-full justify-start text-start">
                <label className="flex flex-col w-full">
                  <span className="font-semibold w-full">Message</span>
                  <textarea id="message" name="message" required className="flex w-full mt-2 px-2 py-2 rounded-md border-indigo-100  text-black" rows={5}></textarea>
                </label>
              </span>
        
        <ContactFormFooter loading={loading} setShowModal={setShowModal} />
        </form>
      </>
  )
}
