import { useEffect } from 'react';
import AboutMedia from '../Content/Home/AboutMedia'
import AboutText from '../Content/Home/AboutText'

export default function About(props:any) {
  const { setShowNav } = props;
  
  useEffect(() => {
    setShowNav(true)
  }, [setShowNav])
  
  return (
    <span className='flex flex-col md:flex-row w-full h-full'>
      <span className='flex h-full w-full'>
        <AboutMedia />
      </span>
      <span className='flex h-full w-full'>
        <AboutText/> 
      </span>
    </span>
  )
}
