import Loading from './Loading';

export default function ContactFormFooter(props: any) {
    const { loading, setShowModal } = props;
  return (
    <>
                {/*footer*/}
                <span className="flex w-full items-center justify-end rounded-b pt-4">
                  <button className="background-transparent font-bold uppercase px-6 py-2 text-sm mr-1 mb-1" type="button" onClick={() => setShowModal(false)}>
                    Close
                  </button>
                <button className="hover:text-black text-white bg-black hover:bg-white border-4 border-white hover:border-black font-bold uppercase text-sm px-6 py-3 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 " type="submit">
            {
              loading ?
                <Loading text="Sending message..."/> :
                  "Contact Us"   }
                  </button>
                </span>
      </>
  )
}
