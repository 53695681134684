import { motion } from "framer-motion";

interface Props {
    children: JSX.Element;
    width?: "fit-content" | "100%",
    duration: number
}

export default function RevealDown({children, duration} : Props) {
  return (
      <div className={`flex flex-col items-center justify-center relative screen overflow-hidden text-center`}>
        <motion.div className='flex flex-col items-center justify-center text-center'
            variants={{
              hidden: { opacity: 0, y: -75 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate="visible"
            transition={{ duration: duration }}>
              {children}  
          </motion.div>
      </div>
  )
}
