import { sanityClient } from "../../client";
import {  getNextThumbnails } from "../../lib/contentQueries/media";
import { useEffect, useState, useRef } from "react";
import { Suspense } from 'react';
import Thumbnail  from './Thumbnail'
import { motion, useScroll, useSpring } from "framer-motion";
import {useIntersection} from '@mantine/hooks'
interface IContentQueueProps {
  category: string,
  updateFocusedContentId: (arg: string) => void

}

export default function ContentQueue(props: IContentQueueProps) {
  const { category, updateFocusedContentId } = props;
  
  const [thumbnails, setThumbnails] = useState([{}] as any[])
  const [lastIdx, setLastIdx] = useState(0 as number)
  
  const contentQueue = useRef(null);

  const { scrollYProgress } = useScroll({
    container: contentQueue
  })
  const scaleX = useSpring(scrollYProgress)
  
  const lastPostRef = useRef<HTMLElement>(null)
  const { ref, entry } = useIntersection({
    root: lastPostRef.current,
    threshold: 0.7
  })

  const fetchNextThumbNails = (query: string) => {
    sanityClient
      .fetch(query)
      .then((data: any) => {
        let _thumbnails = [...thumbnails, ...data.videos ?? [], ...data.images ?? []]
        setThumbnails(_thumbnails.flatMap((thumbnails) => thumbnails))
        updateFocusedContentId(thumbnails[0].id)
        setLastIdx(lastIdx+5)
      })
			.catch(console.error);
  }

  useEffect(() => {
    if (entry?.isIntersecting) {
      const query = getNextThumbnails(category, lastIdx);
      fetchNextThumbNails(query)
    }
    }, [entry])

  useEffect(() => {
    if (thumbnails.length > 1)
      return;
    const query = getNextThumbnails(category, lastIdx);
		sanityClient
      .fetch(query)
      .then((data: any) => {
        if (data) {
          let _thumbnails = [...data.videos ?? [], ...data.images ?? []]
          setThumbnails(_thumbnails)
          updateFocusedContentId(_thumbnails[0].id)
          setLastIdx(lastIdx+5)
        }
      })
			.catch(console.error);
  });
  
  return (
    <span className='flex w-full md:w-3/12 h-full  items-center justify-center bg-black overflow-hidden'>
      
      <span ref={contentQueue} className='flex flex-col w-full h-full right-0 overflow-scroll relative No-Scroll-Bar'>
        <span className="flex flex-col">
          <motion.span style={{
            scaleX: scaleX,
            transformOrigin: 'left',
            background: '#fff',
            position: 'sticky',
            top: 0,
            width: '100%',
            height: '10px',
            zIndex: '90'
          }}/>
          {
            thumbnails.map((content: any, i) => {
              if (i !== 0 && i === thumbnails.length - 1) {
                return <div ref={ref} key={i} className={`bg-slate-black animate-fadeInDown1  hover:cursor-pointer hover:scale-110 transition opacity-90`} onClick={() => updateFocusedContentId(content.id)}>
                  <Suspense fallback={<div className="container text-white">Loading...</div>}>
                    <Thumbnail image={content.thumbnail} />
                  </Suspense>
                </div>
              } else{
                return <div key={i} className={`bg-slate-black animate-fadeInDown1  hover:cursor-pointer hover:scale-110 transition opacity-90`} onClick={() => updateFocusedContentId(content.id ?? '')}>
                  <Suspense fallback={<div className="container text-white">Loading...</div>}>
                    <Thumbnail image={content.thumbnail} />
                  </Suspense>
                </div>
            }
            })
          }
          </span>
      </span>
      
    </span>
  )
}

