import { useEffect,useState } from 'react'
import ImageContainer from './ImageContainer';
import { sanityClient } from '../../client';
import { getFocusedImage, getFocusedVideo } from '../../lib/contentQueries/media';
import VideoPlayer from './MediaPlayer/VideoPlayer';
import ImageCreditsPopOver from '../Credits/ImageCreditsPopOver';
import VideoCreditsPopOver from '../Credits/VideoCreditsPopOver';

export default function FocusedContent(props: any) {
  const { focusedContentId } = props;
  const [imageUrl, setImageUrl] = useState('' as string);
  const [videoUrl, setVideoUrl] = useState('' as string);

  useEffect(() => {
    setImageUrl('')
    setVideoUrl('')
    sanityClient
      .fetch(getFocusedImage(focusedContentId))
      .then((data: any) => {
        setImageUrl(data)
          sanityClient
          .fetch(getFocusedVideo(focusedContentId))
            .then((data: any) => {
          setVideoUrl(data.videoUrl)
          })          
      })
  },[focusedContentId])


  return (
      <span className='flex w-full md:w-10/12 h-3/6 md:h-full items-center justify-center bg-black'>
        <span className='flex flex-col justify-center items-center w-full h-full relative'>
          {
          Object.keys(imageUrl).length > 0 ?
            <>
            <ImageContainer imageUrl={imageUrl} />
            <span className='absolute bottom-20 left-4 h-auto w-auto rounded-full text-white hover:cursor-pointer'>
              <ImageCreditsPopOver focusedContentId={focusedContentId}/>  
            </span>
              </>
            :
            <>
            <VideoPlayer videoUrl={videoUrl} />
            <span className='absolute bottom-20 left-4 h-auto w-auto  rounded-full text-white hover:cursor-pointer'>
              <VideoCreditsPopOver focusedContentId={focusedContentId}/>
            </span>
              </>
          }
        </span>
      </span>
  )
}
