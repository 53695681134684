import React, { useEffect, useState } from 'react'
import { sanityClient } from '../client';
import { getLandingThumbnails } from '../lib/contentQueries/media';

const photos = [
    { src: "/images/image1.jpg", width: 800, height: 600 },
    { src: "/images/image2.jpg", width: 1600, height: 900 },
];
export default function PhotoAlbumContainer() {
  const [thumbNails, setThumbNails] = useState([] as any[])
  
    useEffect(() => {
    if (thumbNails.length > 1)
      return;
		sanityClient
      .fetch(getLandingThumbnails())
      .then((data: any) => {
        let videoResult = [...data.videos].map((video: any) => {
          return { src: video.thumbnail, width: 800, height:900 }
        })
        let imageResult = [...data.images].map((image: any) => {
          return { src: image.thumbnail, width: 800, height:900 }
        })
        let photos = [...videoResult, ...imageResult]
        setThumbNails(photos);
      }).catch(console.error);
    }, [thumbNails]);
  
  return (
    <span className='flex h-full w-screen bg-black z-10 flex-wrap overflow-y-clip brightness-50'>
      {
        thumbNails.map((image: any) => {
          return  <span key={image.src}  className='flex w-1/2 md:w-1/3 lg:w-1/4 h-1/4'>
          <img key={image.src} className='object-cover flex w-full h-full fadeInDown05' src={image.src} alt="" />
        </span> 
        })
      }
     
    </span>
  )
}

