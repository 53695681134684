import { Suspense, useState } from 'react';
import './App.css';
import NavigationPanel from './components/Navigation/NavigationPanel';
import LogoPanel from './components/LogoPanel';
import NavigationRoutes from './components/Navigation/NavigationRoutes';
import ContactButton from './components/Contact/ContactButton';

function App() {
  const [showNav, setShowNav] = useState(false)
  return (
    <div className='flex flex-col w-full h-full'>
      { showNav &&
        <span className='grid col-span-1 min-w-screen h-full relative top-0'>
          <NavigationPanel />
        </span>
      }
      <span className='flex h-[85vh] lg:h-[80vh]'> 
        <Suspense fallback={<div className="container">Loading...</div>}>
          <NavigationRoutes setShowNav={setShowNav} />
        </Suspense>
      </span>
      <span className='flex fixed bottom-24 right-2'>
        <ContactButton />
      </span>
    </div>
  );
}

export default App;
