import { useEffect, useState } from "react"
import { sanityClient } from "../../../client"
import { getAboutImages } from "../../../lib/contentQueries/media"

export default function AboutMedia() {
  const [imageUrl, setImageUrl] = useState('')

  useEffect(() => {
    setImageUrl('')
    sanityClient
      .fetch(getAboutImages())
      .then((data: any) => {
         setImageUrl(data[1].url)
      })
  },[])
  return (
      <span className='flex w-full h-full items-center justify-center overflow-hidden'>
        <img height='100%' width='100%' src={imageUrl} alt='Styling'/>
      </span>
  )
}
