import React, { useEffect, useState } from 'react'
import { sanityClient } from '../../../client'
import {  getAboutText } from '../../../lib/contentQueries/media'
import { PortableText } from '@portabletext/react'

export default function AboutText() {
    const [text, setText] = useState({} as any)

  useEffect(() => {
    setText('')
    sanityClient
      .fetch(getAboutText())
      .then((data: any) => {
        setText(data.text)         
      })
  },[])
  return (
    <span className='flex flex-col w-full h-full items-center justify-center bg-black text-white text-sm lg:text-lg p-8'>
      <span className='flex flex-col text-center space-y-12'>
        <p className='text-3xl animate-fadeInDown05 md:text-7xl text-white z-50 bg-blend-darken'>Usisi Wendwangu</p>
        <PortableText value={text} />
      </span>
      </span>
  )
}
