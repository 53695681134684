export const getInitialThumbnails = ((category: string) => {
  return `*[_type == "category" && name == "${category}"][0]{
  "images": images[@->_id != "" ] | order(_id) [0...5]  ->{
    "id" :_id,
    "thumbnail": image.asset-> url,
  },
  "videos": videos[@->_id != "" ] | order(_id) [0...5] ->{
    "id" :_id,
    "thumbnail": thumbnail.asset-> url
  }
}`
})

export const getNextThumbnails = ((category: string, lastIndex: number) => {
  return `*[_type == "category" && name == "${category}"][0]{
  "images": images[] | order(_id) [${lastIndex}...${lastIndex+4}] ->{
    "id" :_id,
    "thumbnail": image.asset-> url,
  },
  "videos": videos[] | order(_id) [${lastIndex}...${lastIndex+4}]   ->{
    "id" :_id,
    "thumbnail": thumbnail.asset-> url
  }
}`
})



export const getLandingThumbnails = (() => {
  return `*[_type == "category"][0]{
  "images": images[] ->{
    "id" :_id,
    "thumbnail": image.asset-> url,
  },
  "videos": videos[] ->{
    "id" :_id,
    "thumbnail": thumbnail.asset-> url
  }
}`
})

export const getVideoCredits = ((focusedContentId: string) => {
  return `*[_type == "videoDetail"  && _id == "${focusedContentId}"][0]{
    "id" :_id,
    "name":name,
    "production":production,
    "director":director,
    "stylist":stylist
}`
})

export const getImageCredits = ((focusedContentId: string) => {
  return `*[_type == "imageDetail"  && _id == "${focusedContentId}"][0]{
    "id" :_id,
    "name":name,
    "production":production,
    "producer":producer,
    "photographer":photographer,
    "stylist":stylist,
    model,
    makeUpArtist,
    assistant,
    designer,
    createDirection
}`
})

export const getFocusedImage = ((focusedContentId: string) => {
  return `*[_type == "imageDetail"  && _id == "${focusedContentId}"][0]{
    "image":image.asset-> url
}`
})

export const getFocusedVideo = ((focusedContentId: string) => {
  return `*[_type == "videoDetail"  && _id == "${focusedContentId}"][0]{
    "videoUrl":url
}`
})

export const getLogo = `*[_type == "logo"][0]{
  "url": image.asset-> url
}`

export const getAboutImages = (() => {
  return `*[_type == "About"][0].images[]{"url": asset->url}`})

export const getAboutText = (() => {
  return  `*[_type == "About"][0]{
  text
}`})