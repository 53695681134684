
export default function Thumbnail(props: any) {
    const { image} = props

    return (
        <span className='object-cover'>
                <img className='' src={image} alt='Styling_Style' />
        </span>
    )
}
