import { useState,useEffect } from 'react'
import { sanityClient } from '../../client';
import { getImageCredits } from '../../lib/contentQueries/media';
import Popover, { PopoverContent, PopoverHandler } from '@material-tailwind/react/components/Popover';


export default function ImageCreditsPopOver(props:any) {
  const { focusedContentId } = props;
  const [name, setName] = useState<string>('' as any)
  const [production, setProduction] = useState<string>('' as any)
  const [producer, setProducer] = useState<string>('' as any)
  const [photographer, setPhotographer] = useState<string>('' as any)
  const [stylist, setStylist] = useState<string>('' as any)
  const [model, setModel] = useState<string>('' as any)
  const [makeUpArtist, setMakeUpArtist] = useState<string>('' as any)
  const [assistant, setAssistant] = useState<string>('' as any)
  const [designer, setDesigner] = useState<string>('' as any)
  const [createDirection, setCreateDirection] = useState<string>('' as any)

    useEffect(() => {
        if (name === '')
        {
            sanityClient
            .fetch(getImageCredits(focusedContentId))
              .then((data: any) => {
                setName(data.name)
                setProduction(data.production)
                setProducer(data.producer)
                setPhotographer(data.photographer)
                setStylist(data.stylist)
                setModel(data.model)
                setMakeUpArtist(data.makeUpArtist)
                setAssistant(data.assistant)
                setDesigner(data.designer)
                setCreateDirection(data.createDirection)
            }).finally(() => {
            })
	        .catch(console.error);
        }
    },[focusedContentId, name])

    
  return (
      <span className='flex top-4 right-4'>
        <Popover placement="top-end">
                    <PopoverHandler>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 hover:scale-150">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                      </svg>
                    </PopoverHandler>
                    <PopoverContent className='flex flex-col bg-black w-auto'>
                      <span className='flex text-lg border-b-2 p-2 text-white font-semibold'>{name}</span>
                      <span className='flex flex-col p-2 text-white w-full space-y-1'>
                        <span className='flex flex-row whitespace-nowrap'>
                          {stylist ?
                          <span className='flex whitespace-nowrap'>
                            <span className='flex text-sm font-semibold'>Stylist : </span>
                            <span className='flex text-sm'>&nbsp;{stylist}</span>
                          </span>  :
                          <></>
                          }
                        </span>
                        <span className='flex flex-row'>
                          {production ?
                          <span>
                            <span className='flex text-sm font-semibold'>Production : </span>
                            <span className='flex text-sm'>&nbsp;{production}</span>
                          </span>  :
                          <></>
                          }
                        </span>
                        <span className='flex flex-row'>
                          {producer ?
                          <span>
                            <span className='flex text-sm font-semibold'>Producer : </span>
                            <span className='flex text-sm'>&nbsp;{producer}</span>
                          </span>  :
                          <></>
                          }
                        </span>
                        <span className='flex flex-row whitespace-nowrap'>
                          {photographer ?
                          <span className='flex whitespace-nowrap'>
                            <span className='flex text-sm font-semibold'>Photographer : </span>
                            <span className='flex text-sm'>&nbsp;{photographer}</span>
                          </span>  :
                          <></>
                          }
                        </span>
                        <span className='flex flex-row whitespace-nowrap'>
                          {model ?
                          <span className='flex whitespace-nowrap'>
                            <span className='flex text-sm font-semibold'>Model : </span>
                            <span className='flex text-sm'>&nbsp;{model}</span>
                          </span>  :
                          <></>
                          }
                        </span>
                        <span className='flex flex-row whitespace-nowrap'>
                          {makeUpArtist ?
                          <span className='flex whitespace-nowrap'>
                            <span className='flex text-sm font-semibold'>Make up artist : </span>
                            <span className='flex text-sm'>&nbsp;{makeUpArtist}</span>
                          </span>  :
                          <></>
                          }
                        </span>
                        <span className='flex flex-row whitespace-nowrap'>
                          {assistant ?
                          <span className='flex whitespace-nowrap'>
                            <span className='flex text-sm font-semibold'>Assistant : </span>
                            <span className='flex text-sm'>&nbsp;{assistant}</span>
                          </span>  :
                          <></>
                          }
                        </span>
                        <span className='flex flex-row whitespace-nowrap'>
                          {designer ?
                          <span className='flex whitespace-nowrap'>
                            <span className='flex text-sm font-semibold'>Designer : </span>
                            <span className='flex text-sm'>&nbsp;{designer}</span>
                          </span>  :
                          <></>
                          }
                        </span>
                        <span className='flex flex-row whitespace-nowrap'>
                          {createDirection ?
                          <span className='flex whitespace-nowrap'>
                            <span className='flex text-sm font-semibold'>Creative Direction : </span>
                            <span className='flex text-sm'>&nbsp;{createDirection}</span>
                          </span>  :
                          <></>
                          }
                        </span>
                      </span>
                    </PopoverContent>
                  </Popover>
      </span>
  )
}
