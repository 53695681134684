import { useEffect, useState,  } from "react";
import { sanityClient } from "../client";
import { NavLink } from "react-router-dom";


export default function LogoPanel() {
  const [logo, setLogo] = useState('' as string)
  useEffect(() => {
		sanityClient
			.fetch(
				`*[_type == "logo"][0]{
              'url': image.asset-> url
          }`
			)
			.then((data:any) => setLogo(data.url))
			.catch(console.error);
	}, []);
  return (
      <>
      <span className={`animate-fadeInDown15 top-4 left-0 transition-all duration-200 opacity-100 md:top-8 md:left-8 h-28 w-28 md:h-20 md:w-20 lg:w-36 lg:h-36`}>
        <NavLink className="flex items-center text-right text-sm md:text-md text-white" to="/">
          <img className="w-full h-full rounded-full" src={logo} alt="Stylist_logo" />
        </NavLink>
        
      </span>
      </>
  )
}
